<template>
    <div>
        <Form label-position="left" :label-width="120" >
          <FormItem>
              <span slot="label">所属社区:</span>
              <Tag type="dot" style="margin-right: 20px" color="primary" closable @on-close="openSelect" v-if="!showSelect && editRow.orgName">{{ editRow.orgName }}</Tag>
              <Cascader style="width: 300px" v-if="showSelect" v-model="dataScopeList" :data="publishList" :load-data="loadCommunity" change-on-select @on-change="changeCascader"></Cascader>
              <Button type="primary" @click="openSelect" v-if="!showSelect">{{ editRow.orgName && editRow.orgName != "" ? "切换社区" : "选择社区" }}</Button>
              
          </FormItem>
          <FormItem>
               <span slot="label" class="validate">姓名:</span>
                <Input placeholder="请输入您的真实姓名" :maxlength="20" v-model="editRow.name" />
          </FormItem>
           <FormItem>
               <span slot="label" class="validate">现住地址:</span>
               <div style="display:flex">
                    <Input placeholder="请输入您的现住地址" :disabled="true" :maxlength="50" v-model="editRow.addr" @on-change="changeAddress"/>
                    <Button type="primary"  @click="searchLocal">选择</Button>
               </div>
              
            </FormItem>
            <FormItem>
              <span slot="label" class="validate">与住址关系:</span>
             <Select style="width:200px;margin-right:10px" v-model="editRow.userType" placeholder="请选择与住址关系">
                 <Option v-for="(item,index) in houseOptions" :key="index" :value="item.dictKey">{{item.dictValue}}</Option>
             </Select>
          </FormItem>
      </Form>

      <LiefengModal
          title="选择现住地址"
          :value="addrStatus"
          @input="addrStatusFn"
          :fullscreen="false"
          width="500px"
          class="edit"
        
        >
        <template v-slot:title>
            <Button type="primary" icon="ios-add" @click="addHouse" :disabled="addDis">创建房号</Button>
            <p style="margin: 5px 0 0 9px">原住址：{{oldAddr}}</p>
          </template>
          <template v-slot:contentarea>
              <Tree v-if="showTree" :code="treeCode" :name="treeName" :addHouse="addSuccessHouse" @handleTree="handleTree" ></Tree>
          </template>
          <template v-slot:toolsbar>
           <Button style="margin: 0 5px 0 13px" type="warning" @click="addrTip=true;addrStatusFn(false);">取消</Button>
          <Button style="margin: 0 5px 0 13px" type="primary" @click="addrSave">保存</Button>
        </template>
      </LiefengModal>
      <LiefengModal
          title="创建房号"
          :value="addHouseStatus"
          @input="changeAddShouseStatus"
          :fullscreen="false"
          width="500px"
        >
          <template v-slot:contentarea>
            <p style="margin-bottom:30px">所属上级：{{selectTree && selectTree.length !=0 ? selectTree[0].title :''}}</p>
            <span slot="label" class="validate" style="display:inline-block;width:66px;margin-bottom: 20px;">房号:</span>
            <Input  :maxlength="20" v-model="houseData.houseName" style="width:400px"/>
          </template>
          <template v-slot:toolsbar>
           <Button style="margin: 0 5px 0 13px" type="warning" @click="addHouseStatus = false">取消</Button>
          <Button style="margin: 0 5px 0 13px" type="primary" @click="saveAddHouse" :loading="addHouseLoading">保存</Button>
        </template>
      </LiefengModal>
      
    </div>
</template>

<script>
 import LiefengModal from "@/components/LiefengModal";
 import Tree from './tree'
export default {
    name:'suppEdit',
    components:{LiefengModal,Tree},
    props:{
        editRow:{
            type:Object,
            default:()=>{
                return {}
            }
        },
        oldAddr:''
        
    },
    data(){
        return{
            houseOptions:[],
            addrStatus:false,
            addrTip:false,
            showTree:false,
             dataScopeList:[],
             dataNameList:[],
            treeCode:'',
            treeName:'',
            selectTree:[],
            address:'',
            treeData:[],
            bubianName:'',
            // 创建房号
            addHouseStatus:false,
            houseData:{
              houseName:''
            },
            showSelect: false,
            // 发布范围列表
            publishList: [],
            // 新增成功后的房号
            addSuccessHouse:{},
            addDis:true,
            addHouseLoading:false,

        }
    },
    methods:{
        // 获取区
        getPulishData(code, list) {
            this.$get("/old/api/pc/staff/selectCascadeDataScope", {
                orgCode: code,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
            }).then(res => {
                if (res.code == 200) {
                    this[list] = res.dataList.map(item => {
                        return {
                            value: item.orgPath,
                            label: item.orgName,
                            orgLevel: item.orgLevel,
                            loading: false,
                            children: [],
                        }
                    })
                }
            })
        },
        // 动态加载街道/社区
        loadCommunity(item, callback) {
            item.loading = true
            let arrCode = item.value.split("-")
            this.$get("/old/api/pc/staff/selectCascadeDataScope", {
                orgCode: arrCode[arrCode.length - 1],
                oemCode: parent.vue.oemInfo.oemCode,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
            }).then(res => {
                if (res.code == 200) {
                    if (!item.index) {
                        item.children = res.dataList.map(item => {
                            return {
                                value: item.orgPath,
                                label: item.orgName,
                                orgLevel: item.orgLevel,
                                loading: false,
                                children: [],
                                index: 1,
                            }
                        })
                    } else {
                        item.children = res.dataList.map(item => {
                            return {
                                value: item.orgPath,
                                label: item.orgName,
                                orgLevel: item.orgLevel,
                                index: 1,
                            }
                        })
                    }
                    item.loading = false
                    callback()
                }
            })
        },
        formatterData(data, id) {
            let parentId = ""
            if (data && data.length != 0) {
                data.map(item => {
                    if (parentId == "") {
                        if (item.id == id) {
                            this.address = item.name + this.address
                            parentId = item.parentId
                            this.formatterData(item.children, parentId)
                        } else {
                            if (item.children && item.children.length != 0) {
                                this.formatterData(item.children, id)
                            }
                        }
                    } else {
                        if (item.parentId == id) {
                            this.address = item.name + this.address
                        } else {
                            if (item.children && item.children.length != 0) {
                                this.formatterData(item.children, parentId)
                            }
                        }
                    }

                    // if(item.children && item.children.length !=0){
                    //   this.formatterData(item.children,id)
                    // }else{
                    //   console.log('id',id);
                    //   console.log('parentId',item.parentId);
                    // }
                })
            }
            return this.address
        },
        openSelect() {
            this.showSelect = true
            this.editRow.orgName = ""
            this.editRow.addr = ''
            this.treeCode = ''       
            this.editRow.houseCode = ''                // 获取广州市的区
            this.getPulishData(4401, "publishList")
        },
        // 选择级联事件
        changeCascader(val, value) {
            this.dataNameList = []
            this.dataNameList = value
            this.$emit("changeCascader", value)
        },

         // 点击了选择现住地址的选择按钮事件
        searchLocal(){
          // 查询房号社区编码
          console.log(this.treeCode)
          if (!this.treeCode) {
                if (this.dataScopeList.length == 0) {
                    this.$Message.warning({
                        content: "请先选择所属社区",
                        background: true,
                    })
                    return
                } else if (this.dataScopeList.length != 3) {
                    this.$Message.warning({
                        content: "所属社区请选择到社区",
                        background: true,
                    })
                    return
                } else {
                    let code = this.dataScopeList[this.dataScopeList.length - 1].split("-")
                    this.treeCode = code[code.length - 1]
                    this.addrStatus = true
                    this.$nextTick(() => {
                        this.showTree = true
                    })
                }
            } else {
                this.treeCode = this.editRow.orgCode
                this.treeName = this.editRow.orgName
                this.addrStatus = true
                this.$nextTick(() => {
                    this.showTree = true
                })
                
            }
          // if(this.editRow.orgCode == ''){
          //     let code = this.dataScopeList[this.dataScopeList.length -1].split('-')
          //     this.treeCode = code[code.length -1]
          //     this.treeName = this.dataNameList[this.dataScopeList.length -1].label
          //     this.addrStatus = true
          //     this.$nextTick(()=>{
          //       this.showTree = true
          //     })
          // }else{
          //   this.addrStatus = true
          //   this.$nextTick(()=>{
          //       this.showTree = true
          //     })
          //   this.treeCode = this.editRow.orgCode
          //   this.treeName = this.editRow.orgName
          // }
        },
        onSelectChange(val){
          console.log(val)
        },
         // 点击了树节点的东西
        handleTree(val,treeData){
          this.selectTree = val 
          this.treeData = []
          this.treeData = treeData;
          if(this.selectTree.length == 0  ||  (this.selectTree.length != 0 && this.selectTree[0].regionType != 1 && this.selectTree[0].regionType != 2 && this.selectTree[0].regionType != 3 && this.selectTree[0].orgLevel != 6))
          this.addDis = true
          else this.addDis = false
          this.address = '';
          if(this.selectTree.length != 0)
          this.getCurrentTitle(this.selectTree[0].id,treeData);
          
        },
        
            //递归面包屑
        getCurrentTitle(id, treeData) {
        for(let k in treeData) {
            if(treeData[k].id == id) {
            this.address = (treeData[k].name || treeData[k].orgName) + this.address;
            if(!treeData[k].parentId) return;
            this.getCurrentTitle(treeData[k].parentId,this.treeData)
            }else if(treeData[k].children && treeData[k].children.length > 0){
            this.getCurrentTitle(id,treeData[k].children)
            }
        }
        },
         // 取消按钮
        addrSave(){  
          if(this.selectTree && this.selectTree.length ==0){
                this.$Message.warning({
                content:'请选择现住地址',
                background:true
              })
              return
          }else{
            if(this.selectTree[0].regionType != '0' && this.selectTree[0].regionType != '4'){
                this.$Message.warning({
                content:'现住地址请选择到房号',
                background:true
                })
                return
             }else{  
              this.editRow.addr = this.address
              this.editRow.houseCode = this.selectTree[0].code
              this.addrStatus = false
              this.showTree = false
              this.addrTip =false
              
            }
          }
        },
         // 保存按钮
        addrStatusFn(status){
          this.addDis = true
          if(!status && this.addrTip){
            this.addrStatus = status
            this.showTree = false
            this.addrTip =false
          }else{
            this.addrStatus = status
            this.showTree = false
            this.addrTip =false
          }
        },
        // 改变了input框之后
        changeAddress(){
           
        },
        // 字典接口
        getOption(data,model){
            this.$get('/old/api/common/sys/findDictByDictType',{
                dictType:data
            }).then(res=>{
                this[model] = res.dataList          
            })
        },
        // 创建房号
        addHouse(){
          // if(this.selectTree.length == 0  ||  (this.selectTree.length != 0 && this.selectTree[0].regionType != 1 && this.selectTree[0].regionType != 2 && this.selectTree[0].regionType != 3 && this.selectTree[0].orgLevel != 6)){
          //   this.$Message.warning({
          //     content:'请点击选择非房号架构进行新增',
          //     background:true
          //   })
          // }else{
              this.addHouseStatus = true
          // }

        },
        changeAddShouseStatus(val){
          this.addHouseStatus = val
        },
        saveAddHouse(){
          this.addHouseLoading = true
          this.$post('/gx/pc/region/regionAdd',{
            communityCode: this.treeCode,   //社区编码
            orgCode: this.selectTree[0].orgCode || '',    //街巷编码
            parentId: this.selectTree[0].regionId ||  0,  //
            regionName: this.houseData.houseName, //区域名称
            regionType: this.selectTree[0].orgLevel == 6 ? 4 : 0,  //区域类型
            seq: 1,   //排序
            regionDesc: 0,   //区域描述
          }).then(res => {
            this.addHouseLoading = false
            if(res.code == 200){
              this.$Message.success({
                content:'新增成功',
                background:true,
              })
              this.houseData = {houseName:''}
              this.addHouseStatus = false
              if(('expand' in this.selectTree[0] || !('children' in this.selectTree[0])) && res.data){
                this.addSuccessHouse = res.data
                console.log('展开才插入')
                console.log(this.addSuccessHouse)
              }
              
            }else{
              this.$Message.error({
                content:'新增失败',
                background:true,
              })
            }
          }).catch( err => {
            this.$Message.error({
              content:'新增失败',
              background:true,
            })
          })
        }
    },
    created(){
        this.getOption('ONLINE_HOME_USER_TYPE','houseOptions')
    },
     watch:{
      editRow:{
        handler(val,newVal){
          this.showSelect =false
          this.treeCode = val.orgCode
          this.treeName = val.orgName
          this.address = '';
          this.bubianName = val.addr
        }
      },
       dataScopeList:{
            handler(val){
                console.log(val)
                if(val.length == 3 ){
                    let arr = val[2].split('-')
                    console.log(arr)
                    if(arr.length == 5){
                        console.log(this.editRow)
                        this.editRow.authOrgCode = arr[4]
                        console.log(this.editRow.authOrgCode)
                        
                    }
                }else{
                    this.editRow.orgCode = ''
                }
            
            }
        },
    }
}
</script>

<style lang="less" scoped>
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
</style>